
import { defineComponent } from "vue-demi";
import Autopost from "@/components/autopost/List.vue";

export default defineComponent({
  name: "AutopostListView",
  components: {
    Autopost,
  },
});
