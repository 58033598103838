
import { defineComponent } from "vue-demi";
import { AUTOPOST_TABLE_OPTIONS } from "@/store/common/default/table";
import CommonTable from '@/components/common/table/index.vue';

export default defineComponent({
  name: 'AutopostList',
  data() {
    return {
      autoposts: AUTOPOST_TABLE_OPTIONS(),
    }
  },
  components: {
    CommonTable
  }
})
